<!-- 物业榜单页面 -->
<template>
  <div class="">
    <a-tabs default-active-key="1" @change="callback">
      <a-tab-pane key="1" tab="红榜">
        <honourRoll v-if="isShow" />
      </a-tab-pane>
      <a-tab-pane key="2" tab="黑榜" force-render>
        <blacklist v-if="!isShow" />
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import honourRoll from "./honourRoll/index.vue";
import blacklist from "./blacklist/index.vue";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { honourRoll, blacklist },
  data() {
    //这里存放数据
    return {
      isShow: true,
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    callback(key) {
      this.isShow = !this.isShow;
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
</style>